export interface State {
  gift_vouchers?: object,
  gift_memberships?: object,
  redemption_code?: string,
  redemption_voucher?: object
}

export const state = (): State => ({
  gift_vouchers: undefined,
  gift_memberships: undefined,
  redemption_code: undefined,
  redemption_voucher: undefined
})

export default state
