









































// @ts-ignore:next-line
import { Component, Vue } from 'nuxt-property-decorator'
// @ts-ignore:next-line
import ChevronLeftSvg from '@icons/material/svg/chevron-left.svg?inline'
import Back from '@UI/components/buttons/Back.vue'
import HeaderPart from '@Core/components/parts/header/HeaderPart.vue'
import FooterPart from '@Core/components/parts/footer/FooterPart.vue'
import ResizeObserver from '@UI/components/resize-oberserver/ResizeObserver.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'

@Component({
  components: {
    ChevronLeftSvg,
    Back,
    HeaderPart,
    FooterPart,
    ResizeObserver,
    AlertNotification,
  },
})
export default class RedeemLayout extends Vue {}
