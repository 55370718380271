import Vue from 'vue'
import { MutationTree } from 'vuex'
import { types } from './types'
import { State } from './state'

const mutations: MutationTree<State> = {
  [types.ADD_GIFT_VOUCHER](state: State, payload: any): void {
    if (state.gift_vouchers === undefined) {
      state.gift_vouchers = {}
    }
    state.gift_vouchers[payload.id] = payload.details
  },

  [types.REMOVE_GIFT_VOUCHER](state: State, payload: any): void {
    if (state.gift_vouchers !== undefined && state.gift_vouchers[payload] !== undefined) {
      delete(state.gift_vouchers[payload])
    }
  },

  [types.ADD_GIFT_MEMBERSHIP](state: State, payload: any): void {
    if (state.gift_memberships === undefined) {
      state.gift_memberships = {}
    }
    state.gift_memberships[payload.id] = payload.details
  },

  [types.REMOVE_GIFT_MEMBERSHIP](state: State, payload: any): void {
    if (state.gift_memberships !== undefined && state.gift_memberships[payload] !== undefined) {
      delete(state.gift_memberships[payload])
    }
  },

  [types.REDEMPTION_CODE](state: State, payload: any): void {
    state.redemption_code = payload
  },
  
  [types.REDEMPTION_VOUCHER](state: State, payload: any): void {
    state.redemption_voucher = payload
  },
}

export default mutations
