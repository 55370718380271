import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { cloneDeep } from 'lodash'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async addGiftMembership(
    context: ActionContext<State, RootState>,
    addGiftMembershipInput
  ): Promise<any> {
    const data = await this.$gifts.addGiftMembership(addGiftMembershipInput)

    if (data.addGiftMembership !== false) {
      context.commit(types.ADD_GIFT_MEMBERSHIP, {
        id: data.addGiftMembership,
        details: addGiftMembershipInput,
      })
    }

    return data.addGiftMembership
  },

  async addGiftCertificate(
    context: ActionContext<State, RootState>,
    addGiftCertificateInput
  ): Promise<any> {
    const data = await this.$gifts.addGiftCertificate(addGiftCertificateInput)

    if (data.addGiftCertificate !== false) {
      context.commit(types.ADD_GIFT_VOUCHER, {
        id: data.addGiftCertificate,
        details: addGiftCertificateInput,
      })
    }

    return data.addGiftCertificate
  },

  async removeGiftCertificate(
    context: ActionContext<State, RootState>,
    gc_no
  ): Promise<any> {
    // remove the voucher from the store so we don't send any email on order completion
    const vouchers = this.getters['basket/vouchers']
    const id = vouchers.find((v) => v.extra.certificate_reference === gc_no)
    if (id) {
      // same method is used to remove vouchers and memberships, so check both stores
      context.commit(types.REMOVE_GIFT_VOUCHER, id.item_ref)
      context.commit(types.REMOVE_GIFT_MEMBERSHIP, id.item_ref)
    }

    const data = await this.$gifts.removeGiftCertificate(gc_no)

    return data.removeGiftCertificate
  },

  async processGiftMembershipConfirmations(
    context: ActionContext<State, RootState>,
    order
  ): Promise<any> {
    if (context.state.gift_memberships !== undefined) {
      const vouchers = order.groups.find(
        (group) => group.name === 'GiftCertificate'
      )

      for (const key of Object.keys(context.state.gift_memberships)) {
        if (
          context.state.gift_memberships &&
          context.state.gift_memberships[key]
        ) {
          let details = cloneDeep(context.state.gift_memberships[key])

          const recipient = details.recipient
            ? details.recipient
            : context.rootState.customer?.customer?.meta.email
          const from = details.from
            ? details.from
            : context.rootState.customer?.customer?.meta.email
          const name = details.input.name
            ? details.input.name
            : context.rootState.customer?.customer?.display_name

          const input = Object.assign({}, details.input, {
            name,
          })
          details = Object.assign(details, {
            recipient,
            from,
            input,
          })

          const voucher = vouchers
            ? vouchers.items.find((v) => parseInt(v.item_ref) === parseInt(key))
            : null

          let output
          if (recipient && voucher) {
            output = await this.$gifts.sendGiftEmail(
              details,
              voucher,
              'gift_membership'
            )
          }
          context.commit(types.REMOVE_GIFT_MEMBERSHIP, key)
        }
      }

      return true
    }
  },

  async processGiftCertificateConfirmations(
    context: ActionContext<State, RootState>,
    order
  ): any {
    if (context.state.gift_vouchers !== undefined) {
      const vouchers = order.groups.find(
        (group) => group.name === 'GiftCertificate'
      )

      for (const key of Object.keys(context.state.gift_vouchers)) {
        if (context.state.gift_vouchers && context.state.gift_vouchers[key]) {
          let details = cloneDeep(context.state.gift_vouchers[key])

          const recipient = details.recipient
            ? details.recipient
            : context.rootState.customer?.customer?.meta.email
          const from = details.from
            ? details.from
            : context.rootState.customer?.customer?.meta.email
          const name = details.input.name
            ? details.input.name
            : context.rootState.customer?.customer?.display_name

          const input = Object.assign({}, details.input, {
            name,
          })
          details = Object.assign(details, {
            recipient,
            from,
            input,
          })

          const voucher = vouchers
            ? vouchers.items.find((v) => parseInt(v.item_ref) === parseInt(key))
            : null

          let output
          if (recipient && voucher) {
            output = await this.$gifts.sendGiftEmail(
              details,
              voucher,
              'gift_voucher'
            )
            context.commit(types.REMOVE_GIFT_MEMBERSHIP, key)
          }
        }
      }

      return true
    }
  },

  async setRedemptionCode(
    context: ActionContext<State, RootState>,
    code: string
  ) {
    const voucher = await this.$gifts.getGiftCertificateBalance(code)
    context.commit(types.REDEMPTION_CODE, code)
    context.commit(types.REDEMPTION_VOUCHER, voucher.getGiftCertificateBalance)
    return voucher.getGiftCertificateBalance
  },

  async applyGiftMembership(
    context: ActionContext<State, RootState>,
    applyGiftMembershipInput: ApplyGiftMembershipInput
  ) {
    const data = await this.$gifts.applyGiftMembership(applyGiftMembershipInput)
    return data.applyGiftMembership
  },
}

export default actions
