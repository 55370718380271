import VueGtm, {
  GtmSupportOptions,
  VueGtmUseOptions,
} from '@gtm-support/vue2-gtm'
import Vue from 'vue'
import gtmEvents from '@ad/gtm'
import { config } from '@Core/config/skyway'

export default async ({ app, route }, inject) => {
  const tags: string | string[] = config.GOOGLE_TAG_MANAGER.split(',')
  if (!tags[0]) tags[0] = 'GTM-P2HJS29' // @todo add back to options.id

  const analyticsEnabled = (cookie) => {
    if (!cookie) return false
    return Boolean(cookie.session && cookie.session.cookieConsent)
  }

  const options: GtmSupportOptions | VueGtmUseOptions = {
    id: ['GTM-5ZP7293', 'GTM-N9C55L6'],
    enabled: true, // analyticsEnabled(app.$cookies.get('vuexSession')),
    debug: true,
    loadScript: true,
    vueRouter: app.router,
  }

  Vue.use(VueGtm, options)

  const gtm = Vue.gtm

  app.store.subscribe((mutation, state) => {
    if (mutation.type === 'session/SET_COOKIE_PREFERENCES') {
      if (mutation.payload.includes('analytics')) {
        gtm.enable(true)
      } else {
        gtm.enable(false)
      }
    }
  })

  const eventsInstance = gtmEvents(['ga4'], gtm, config)

  inject('gtmEvents', eventsInstance)
  inject('gtm', gtm)
}
