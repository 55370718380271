import * as gifts from '@Gifts/api/queries/gifts.gql'
import BaseService from '@Core/services/baseService'

export default class GiftsService extends BaseService {
  async addGiftMembership(addGiftMembershipInput): Promise<any> {
    const response = await this.client.mutate({
      mutation: gifts.addGiftMembership,
      variables: {
        addGiftMembershipInput: addGiftMembershipInput.input,
      },
    })

    return this.respond(response)
  }

  async addGiftCertificate(addGiftCertificateInput): Promise<any> {
    const response = await this.client.mutate({
      mutation: gifts.addGiftCertificate,
      variables: {
        addGiftCertificateInput: addGiftCertificateInput.input,
      },
    })

    return this.respond(response)
  }

  async removeGiftCertificate(gc_no): Promise<any> {
    const response = await this.client.mutate({
      mutation: gifts.removeGiftCertificate,
      variables: {
        gc_no,
      },
    })

    return this.respond(response)
  }

  async getGiftCertificateBalance(code: string) {
    const response = await this.client.mutate({
      mutation: gifts.getGiftCertificateBalance,
      variables: {
        code,
      },
    })
    return this.respond(response)
  }

  async applyGiftMembership(
    applyGiftMembershipInput: ApplyGiftMembershipInput
  ) {
    const response = await this.client.mutate({
      mutation: gifts.applyGiftMembership,
      variables: {
        applyGiftMembershipInput,
      },
    })

    return this.respond(response)
  }

  async sendGiftEmail(details, voucher, template) {
    const response = await this.client.mutate({
      mutation: gifts.sendCustomerEmail,
      variables: {
        emailInput: {
          sender: {
            name: details.from,
          },
          recipient: {
            name: details.input.name,
            email: details.recipient,
          },
          template_code: template,
          extra: {
            ref: voucher.extra.certificate_reference,
            amount: Math.round(details.input.amount * 1e2) / 1e2, // Use math.round rather than toFixed so output is float rather than string
            message: details.message,
          },
        },
      },
    })

    return this.respond(response)
  }
}
